.filter-menu {
  width: 200px;
  background-color: white;
  position: absolute;
  right: 0;
}

.highlightHover:hover {
  background-color: rgba(43, 45, 47, 0.3);
}

.highlightHover input {
  cursor: pointer;
}
