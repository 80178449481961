.dataRow {
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #1a1a1a;
}

.boldCell {
  font-size: 14px;
  font-weight: bold;
}
