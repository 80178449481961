.close {
  top: 201px;
  left: 1042px;
  width: 11px;
  height: 11px;
  float: right;
  opacity: 1;
  margin: 1%;
  font-weight: bold;
}

.frameless-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 4%;
}

.positionFix {
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: -4px 1px 10px #0000000f;
  background: #ffffff;
}
.popup-container {
  overflow-y: scroll;
}
.download-icon {
  padding-right: 10px;
}
.header {
  margin-left: 7px;
}
.right-child {
  align-items: baseline;
}
