.mm-points-table {
  border-collapse: collapse;
  font-size: 0.8rem !important;
}

.mm-points-table th,
.mm-points-table td {
  text-align: left;
  border: none;
}

.mm-points-table table {
  width: 100%;
}

.mm-points-table thead tr {
}

.mm-points-table tbody tr {
}

.mm-points-table th,
.mm-points-table td {
  text-align: left;
  border: none;
}

.mm-points-table thead th:nth-child(1),
.mm-points-table tbody tr td:nth-child(1) {
  /* width: 20%;  */
}

.mm-points-table thead th:nth-child(2),
.mm-points-table tbody tr td:nth-child(2) {
  /* width: 20%;  */
}

.mm-points-table thead th:nth-child(3),
.mm-points-table tbody tr td:nth-child(3) {
  /* width: 20%;  */
}

.mm-points-table thead th:nth-child(4),
.mm-points-table tbody tr td:nth-child(4) {
  /* width: 20%;  */
}

.mm-points-table tbody tr:hover {
  background-color: white;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
