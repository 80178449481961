.triangle-div {
  position: relative;
}

.triangle-div::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -25px; /* Adjust this value to control the position of the triangle */
  transform: translateY(-50%);
  border-style: solid;
  border-width: 30px 0 30px 30px; /* Adjust the border width to control the size of the triangle */
  border-color: transparent transparent transparent #f7f7f7; /* Match the background color of the div */
}

.widget-container {
  height: 300px;
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0px 3px 6px #00000029;
  margin: auto;
  padding-top: 5px;
  .date-input {
    margin-left: 40px;

    height: 32px;
    border: 1px solid #dde5f3 !important;
    width: 160px;
    border-radius: 6px !important;
    color: #333;
    font-size: 15px;
    padding: 0px !important;
  }

  .dot-1 {
    height: 7px;
    width: 7px;
    background-color: #00c48c;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-2 {
    height: 7px;
    width: 7px;
    background-color: #e3402f;
    border-radius: 50%;
    display: inline-block;
  }

  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: flex-end;
    height: 14%;
  }
  .total {
    font-size: 14px;
    span {
      color: #707070;
      font-weight: 500;
    }
  }
  .body {
    display: flex;
    height: 86%;
  }
  .pie-container {
    display: flex;
    display: contents;
  }
  .zone-container {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
    font-size: 12px;
    width: 200px;
    margin-left: -110px; //to overlap with pie chart container

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      span > div {
        width: 95px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }
    .circle {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
  }

  .pdf-pie-container {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
    font-size: 12px;
    width: 200px;
    margin-left: -110px; //to overlap with pie chart container

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      span > div {
        width: 95px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }

    .circle {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
  }
}

.widget-container4 {
  height: 267px;
  width: 100%;
  background-color: #f7f7f7;
  box-shadow: -6px 3px 7px #00000029;
  margin: auto;
  padding-top: 5px;
  .date-input {
    margin-left: 40px;

    height: 32px;
    border: 1px solid #dde5f3 !important;
    width: 160px;
    border-radius: 6px !important;
    color: #333;
    font-size: 15px;
    padding: 0px !important;
  }

  .dot-1 {
    height: 7px;
    width: 7px;
    background-color: #00c48c;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-2 {
    height: 7px;
    width: 7px;
    background-color: #e3402f;
    border-radius: 50%;
    display: inline-block;
  }

  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: flex-end;
    height: 14%;
  }
  .total {
    font-size: 14px;
    span {
      color: #707070;
      font-weight: 500;
    }
  }
  .body {
    display: flex;
    height: 86%;
  }
  .pie-container {
    display: flex;
    display: contents;
  }
  .zone-container {
    display: flex;
    // align-items: flex-end;
    padding-bottom: 1rem;
    font-size: 12px;
    // width: 200px;
    //to overlap with pie chart container

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      span > div {
        width: 95px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }
    .circle {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
  }

  .pdf-pie-container {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
    font-size: 12px;
    width: 200px;
    margin-left: -110px; //to overlap with pie chart container

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      span > div {
        width: 95px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }

    .circle {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
  }
}

.white-background {
  background-color: #fff;
}

.widget-container2 {
  height: 267px;
  width: 100%;
  background-color: #fff;
  box-shadow: -3px 3px 7px #00000029;
  margin: auto;
  padding-top: 5px;

  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: flex-end;
    height: 14%;
  }
  .total {
    font-size: 14px;
    span {
      color: #707070;
      font-weight: 500;
    }
  }
  .body {
    display: flex;
    height: 86%;
  }
  .pie-container {
    display: flex;
    display: contents;
  }
  .zone-container {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
    font-size: 12px;
    width: 200px;
    margin-left: -110px; //to overlap with pie chart container

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      span > div {
        width: 95px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }
    .circle {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
  }
  .pdf-pie-container {
    div {
      display: flex;

      span > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }

    .circle {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
  }
}

.widget-container3 {
  height: 267px;
  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: flex-end;
    height: 14%;
  }
  .sub-header {
    font-size: 12px;
    font-weight: 600;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: flex-end;
    height: 14%;
  }

  .body {
    display: flex;
    height: 86%;
  }

  .pie-container {
    display: flex;
    display: contents;
  }

  .pdf-pie-container {
    div {
      display: flex;

      span > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }

    .circle {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
  }
}
