/* EllipsisDropdown.css */
.ellipsis-dropdown {
  display: inline-block;
}

.ellipsis-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px; /* Adjust size as needed */
  padding: 0px 4px;
}

.ellipsis-button:hover {
  background-color: #e6f0e7;
}

.ellipsis-button:focus {
  background-color: #e6f0e7;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #e6f0e7;
}
