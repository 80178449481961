.details-grid-container {
  display: grid;
  margin: 4% 0% 0% 5%;
  grid-template-columns: 85% 40%;
  width: 75%;
}

.bin-details-grid {
  margin: 3% 0% 2% 2%;
  width: 90% !important;
}

.bin-details-item-label,
.horizontal-separation {
  color: #6e6e6e !important;
}

.bin-default-details-section-heading {
  color: #6e6e6e;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bin-details-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bin-details-item-value {
  font-weight: bold !important;
}

.details-header-container {
  width: 100%;
}
.details-header-text {
  color: #000000;
  font: normal normal bold 18px/24px Open Sans;
}
.detail-status-label {
  color: white;
  padding: '8px 16px';
  border-radius: 20px;
}
.frameless-close-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-container-ellipses {
  display: flex;
  align-content: flex-end;
  align-items: baseline;
  justify-content: end;
  width: 100%;
  transform: translateX(-15%);
}

.close {
  font-size: medium;
  margin-top: 2%;
}

.details-action-button {
  font-family: inherit !important;
  background-color: #333 !important;
  width: 100% !important;
  text-transform: none !important;
}

.details-action-button:hover {
  background-color: #f2fff7 !important;
  border: 1px solid #21b291;
  color: #000000;
}

.details-action-button-container {
  margin-top: 7%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.detail-header {
  margin-bottom: 20px;
  color: #383838; /* Darkest grey for headers */
}

.detail-actions {
  margin-top: 20px !important;
}

.truck-details .header h1 {
  margin: 0;
  color: #383838; /* Darkest grey for important text */
}

.truck-details .header .status {
  color: #a1a1a1; /* Medium grey for less prominent text */
  font-size: 0.9em;
}

.truck-details .content {
  padding: 16px;
}

.truck-details .section {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.truck-details .section .icon {
  font-size: 1.5em;
  margin-right: 8px;
}

.truck-details .section .text .title {
  font-weight: bold;
}

.truck-details .section .text .value {
  margin-left: 4px;
  color: #707070; /* Dark grey for text */
}

.truck-status {
  /* Layout Properties */
  top: 234px;
  left: 450px;
  width: 262px;
  height: 30px;
  /* UI Properties */
  background: #3d63c9 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
}

.truckDetailsContainer {
  margin: 4% 7% 0% 5%;
}

.truckDetailsContainer p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Open Sans', sans-serif;
  color: #6e6e6e;
  text-align: left;
}

.variableText {
  text-align: left;
  color: #000000;
  font-weight: bold;
  display: block;
}

.summaryHeaders {
  display: grid;
  margin: 4% 0% 2% 0.5%;
  grid-template-columns: 50% 70%;
  width: 90%;
}

.buttonText {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-16) / var(--unnamed-line-spacing-22)
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 15px/21px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.details-container-operation-truck {
  display: flex;
  justify-content: flex-end;
  width: 95%;
}
