.main-container {
  display: flex;
  height: 100vh;
}
.inner-container {
  width: 100%;
  overflow: auto;
}
.inner-container-not-overflow {
  width: 100%;
  overflow: hidden;
}
