.timer-loads-due {
  font-size: 18px;
}

.timer-loads-due span {
  font-size: 22px;
  text-shadow: 0.5px 0 black;
}

.timer-time {
  font-size: 30px;
}
