.flyout-close-button {
  cursor: pointer;
}

.scrapbins-detail-popup {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  min-width: 300px;
  bottom: 0;
  right: -30px;
  font-size: 0.8rem;
}

.flyout-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.flyout-close-button img {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.content {
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: left;
}

.bin-title {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  color: #000000;
  font-family: 'Open Sans';
}

.status-tag {
  background-color: #34a853;
  border-radius: 20px;
  color: white;
  padding: 5px 10px;
  display: inline-block;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.action-buttons {
  text-align: left;
  padding-left: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #0053d8;
  //padding:20px
}

.action-button {
  color: #007bff;
  padding: 4px 0;
  border-radius: 0;
  background-color: transparent;
  text-align: left;
  font-weight: bold;
  margin-bottom: 4px;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
}

.track-bin,
.view-details {
  text-align: left;
  display: block;
}
.truck-modal-label {
  font: normal normal 600 14px/17px Open Sans;
  color: #6a6a6a;
}
.truck-modal-value {
  font: normal normal bold 14px/17px Open Sans;
  color: #000000;
}
.truck-modal-underline {
  font: normal normal bold 14px/17px Open Sans;
  color: #000000;
  text-decoration: underline;
}
