.sidebar-container {
  width: 3rem;
  display: flex;
  flex-direction: column;
  background: #2b2d2f;
  transition: width 0.1s;
  color: white;
}
.sidebar-active {
  width: 220px;
  justify-content: flex-start;

  .active-menu {
    color: white;
    font-weight: bold;
  }
  .hamburger-container {
    height: 85px;
    padding: 20px 20px 20px 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.hamburger {
  height: 14px;
  width: 28px;
  cursor: pointer;
}
.flip-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.sidebar-menu {
  font-size: 12px;
  padding-top: 5px;
  width: 100%;
  padding: 0px 14px;

  .sub-menu-icon {
    font-size: 8px;
    align-items: center;
  }
  .sub-menu-text {
    width: 55px;
    font-weight: normal;
    text-wrap: nowrap;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 3px;
    .iconContainer {
      padding: 0;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background-color: white;
    }
  }
  .active {
    .iconContainer {
      background-color: #4f74d9;
    }
  }
}
