.bin-level-modal {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bin-level-tile {
  position: relative;
  background-color: #fff;
  display: flex;
  border: 1px solid #e2e2e2;
  align-items: center;
  height: 56px !important;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.bin-level-tile:hover {
  border-color: #b3b3b3;
  background-color: #f5f5f5;
}

.bin-level-tile-active {
  background-color: #f2fff7;
  border-color: #21b291;
}

.bin_level-circle {
  width: 19px;
  height: 19px;
  border-radius: 10px;
  margin: 0px 22px;
  z-index: 9999;
}

.bin_level-bigCircle {
  width: 33px;
  height: 33px;
  border-radius: 30px;
  margin: 5px 0;
  top: 6px;
  left: 15px;
  position: absolute;
  border: 3px solid;
}

.bin_level-title {
  font-size: 18px;
  font-weight: 800;
}
