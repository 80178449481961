.alert-text {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 25px 25px;
  gap: 20px;
  max-width: 300px;
  text-align: center;
}
