.navbar-text {
  font-size: 18px;
  color: #1a1a1a;
  font-weight: bold;
  margin-left: 10px;
}

.user {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-left: 14px;
}

.profile-1 {
  border: 1px solid rgb(222, 215, 215);
  border-radius: 20px;
  height: 40px;
  width: 40px;
  object-fit: fill;
}

.avatar-circle {
  border-radius: 50%;
  border: 1px solid #dde5f3;
  font-size: 11px;
  font-weight: bold;
  font-family:
    Open Sans,
    roboto,
    arial;
  color: white;
  text-align: center;
  background: #2b2d2f;
  color: white;
  cursor: pointer;
  img {
    height: 18px;
    margin-bottom: 0.2rem;
  }
}

.small-avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.header-dropdown {
  margin-top: 0px;
  width: 100%;
  max-width: 20rem;
}

.dept-header-dropdown {
  margin-top: 0px;
  width: 100%;
}

.dropdown-icon-container {
  flex: 1;
}
