.zone-table-container {
  max-width: 990px;
  width: 100%;
}

.zone-table {
  margin-top: 10px;
}
.zone-table > tbody > tr > td {
  padding: 0px 2px 0px 2px;
}

.table-text {
  font-size: 12px;
}
.table-text:not(.alerted-row):hover {
  background: transparent;
}
table {
  border-spacing: 0px;
}

.label-div {
  background-color: #f2f2f2;
  width: 250px;
  border-radius: 8px;
  padding: 10px;
  height: fit-content;
  margin: 2.75rem 0 0 1.5rem;
}
.square-box {
  height: 20px;
  width: 20px;
}

.label-text {
  font-size: 12px;
  gap: 1.5rem;
  padding: 0.5rem;
}

div.hazard-mm-modal-position {
  position: fixed !important;
  top: 0;
  right: 0;
  margin-top: 10rem;

  width: 100%;
  max-width: 75vw;
  font-size: 14px;
  // border: 2px solid black;
  div.modal-content {
    height: 100vh !important;
  }

  div.modal-content > div.modal-header {
    border-bottom: none;
  }
}

.icon-click {
  border: none;
  background-color: white;
}
.modal-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-width: 0;
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 10rem;
  button {
    width: 70%;
    flex: 1;
  }
}

.delete-icon {
  height: 17px;
  cursor: pointer;
}
.edit-icon {
  cursor: pointer;
}

.edit-hazards-container {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.hazard-zone-card {
  width: 350px;
  height: 255px;
  display: flex;
  flex-direction: column;
}
.hazard-zone {
  color: #1a1a1a;
  font-weight: bold;
  font-size: 14px;
}
.hazard-textbox {
  height: 220px;
  width: 100%;
  overflow: auto;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
.hazard-textbox:focus {
  outline: none;
  border: 1px solid #186fd9 !important;
}
