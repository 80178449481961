/* Timeline.css */
.timeline-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  /* background-color: aqua; */
  overflow: visible;
}

.segment {
  transition: fill 0.3s ease;
}

.time-label,
.duration-label {
  font-size: 12px;
  pointer-events: none;
}
