.date-select {
  padding: 3px 3px 3px 10px;
  margin: 6px 7px 0 0;
  border: 1px solid #e1e8f4;
  background: #f0f4fb;
}
#zone-report-container {
  overflow-y: scroll;
  max-height: 70vh;
}
.zone_header > thead {
  background-color: #f5f7fa;
}
.zone_header > thead:first-of-type {
  border-bottom: 1px solid #dde5f3;
}
.zone_header > thead > tr > th:nth-child(4) {
  width: 10%;
  text-align: left;
}
.zone_header > thead > tr > th:nth-child(5) {
  width: 50%;
  text-align: left;
}

.zone-report-table > tbody > tr > td:nth-child(4) {
  width: 10%;
}
.zone-report-table > tbody > tr > td:nth-child(5) {
  width: 40%;
}

.zone_header > thead > tr > th:nth-child(2),
.zone_header > thead > tr > th:nth-child(3) {
  text-align: left;
  width: 10%;
}
.zone_header > thead > tr > th:nth-child(1),
.zone-report-table > tbody > tr > td:nth-child(1) {
  width: 4%;
  text-align: left;
  padding-left: 1rem;
}

.zone-report-table > tbody > tr > td:nth-child(2),
.zone-report-table > tbody > tr > td:nth-child(3) {
  width: 8%;
}
@media screen and (max-width: 1286px) {
  .zone_header > thead > tr > th:nth-child(1),
  .zone_header > thead > tr > th:nth-child(2),
  .zone_header > thead > tr > th:nth-child(3),
  .zone_header > thead > tr > th:nth-child(4),
  .zone_header > thead > tr > th:nth-child(5) {
    padding-left: 10px !important;
    padding-right: 0px !important;
  }
}
.daily-water {
  display: flex;
  justify-content: center;
  div {
    display: flex;
    width: 90%;
    flex-direction: column;
    font-size: 12px;
    justify-content: space-between;
  }
  .water-data {
    font-size: 14px;
    font-weight: bold;
  }
}

.download-icon {
  cursor: pointer;
  padding-top: 10px;
}
.span-7-cell {
  margin-left: auto;
}
.date-container {
  background: #2d3f5a;
  width: fit-content;
  color: white;
  border-radius: 9px;
  padding: 6px;
}
.water-consumption {
  flex: 1;
  background-color: black;
}
.icon-container {
  width: 30px;
  height: 30px;
  // gap: 2rem;
}
.download-icons {
  position: absolute;
  top: 0;
  right: 0;
}
.cb {
  width: 100%;
}
.span-7-cell {
  border-width: 0px 2px 2px 2px;
  border-color: #e1e8f4;
}
.bottom-width {
  border-bottom-width: 2px;
  border-color: #e1e8f4;
}
.top-width {
  border-top-width: 2px;
  border-color: #e1e8f4;
}
.zone-report-table {
  width: 100%;
}
.font-weight-bold {
  font-weight: bold;
}
#zone-report-container::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}
#zone-report-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}
.zone-report-main-container {
  font-size: 13px;
}
.center-zone-text {
  text-align: center;
}
.truck-activity-table {
  height: 100%;
  width: 100%;
  thead {
    background-color: #f5f7fa;
  }
  tbody > tr:nth-child(3) {
    border: none;
  }
}
.truck-header > th:nth-child(1) {
  width: 10%;
}
.truck-header > th:nth-child(2) {
  width: 25%;
}
.truck-header > th:nth-child(3) {
  width: 15%;
}
.truck-header > th:nth-child(4) {
  text-align: center;
  width: 25%;
}
.truck-header > th:nth-child(5) {
  text-align: center;
  width: 25%;
}
.border-red {
  border: 2px solid red;
}
.sub-data-header {
  th {
    span {
      // border: 2px solid black;
      width: 50%;
    }
  }
}
.sub-data-item {
  span {
    // border: 2px solid black;
    width: 50%;
  }
}
